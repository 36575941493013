/*!
 * IE10 viewport hack for Surface/desktop Windows 8 bug
 * Copyright 2014-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// See the Getting Started docs for more information:
// http://getbootstrap.com/getting-started/#support-ie10-width

(function () {
  'use strict';

  if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
    var msViewportStyle = document.createElement('style')
    msViewportStyle.appendChild(
      document.createTextNode(
        '@-ms-viewport{width:auto!important}'
      )
    )
    document.querySelector('head').appendChild(msViewportStyle)
  }

  if ($('#full-video').length > 0) {
    document.getElementById("full-video").play();
  }

  
  $('[data-toggle="tooltip"]').tooltip();

  $("#destination").focus(function(){
    $(this).removeClass('--selectfocus');
    $(this).addClass('--selectfocus');
  });

  $("#destination").blur(function(){
    $(this).removeClass('--selectfocus');
  });

  $("#destination").on('mousedown',function(){
    $(this).removeClass('--selectfocus');
  });

  $('[data-toggle="tooltip"]').tooltip()
  
  $(document).on('click', '[data-toggle="lightbox"]', function(event) {
    event.preventDefault();
    $(this).ekkoLightbox({
      always_show_close: false,
      title: false
    });
  });

})();